[dir="rtl"] .create-cashier * {
  font-family: "ZahraArabic", "Montserrat" !important;
}

[dir="rtl"] .add-cashier-content .add-cashier-title {
  font-size: 1.9rem !important;
  font-weight: 700 !important;
}

[dir="rtl"] .add-cashier-form label {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #626262 !important;
}
[dir="rtl"] .add-cashier-text-error {
  color: #ec1c24 !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
[dir="rtl"] .add-cashier-input-field {
  background-color: #e4e4e4;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #626262 !important;
}
[dir="rtl"] .add-new-cashier-btn {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}
