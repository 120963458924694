/* Scannner  */
.drawingBuffer{
    position: absolute;
}
.viewport video{
    width: 100%;
}
.scanCode{
    font-weight: 700!important;
    font-family: "Montserrat"!important;
}
.reload-icon{
    font-size: 17px!important;
}