[dir = "rtl"] .customer-second-step *{
  font-family: "adoody" !important;
  font-size: 1rem!important;
}

[dir = "rtl"] .text-error{
    color: #EC1C24!important;
    font-weight: 400!important;
    font-size: 1rem!important;
  }
