.manager-home {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
  display: flex;
  justify-content: center;
}

.owner-merchant-logo {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50%;
  background-color: #ed1c24;
  object-fit: contain;
}
.manager-home-title {
  font-size: 1.5rem;
  font-weight: 900;
}
.manager-home-category{
  color: gray!important;
}
.redeem-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ed1c24;
  color: white;
  height: 50vh;
  border: 3px solid white;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
}
.redeem-content:hover{
  background-color: #ca070d;
  transition: all 0.2s;
}

.redeem-content h5 {
  font-size: 1.5rem;
  font-weight: 700;
}
.report-btn,
.add-cashier-btn {
  background-color: white !important;
  border: 3px solid #ed1c24 !important;
  font-weight: 800 !important;
  color: #ed1c24 !important;
}

.report-icon,
.cashier-icon {
  font-size: 2rem;
}

.footer {
  text-align: center !important;
}
.footer img {
  width: 250px;
  height: auto;
  object-fit: contain;
}

.merchant-info-btn{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent!important;
  border: none!important;
}
.merchant-info-icon{
  color: #17A2B8!important;
  cursor: pointer;
  font-size: 35px;
}