.modal-body-otp,
.modal-content {
  background-color: #e4e4e4 !important;
  border-radius: 15px;
}
.login-otp-group {
  background-color: white;
  border-radius: 15px;
}
.login-otp-group-list {
  background-color: #f1f0f0;
  border-radius: 15px;
}
.login-otp-input {
  border: 2px solid #c80000 !important;
  border-radius: 10px !important;
  outline: none !important;
  font-weight: 800 !important;
  font-family: "Montserrat" !important;
  color: #626262 !important;
}
.login-otp-input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.login-start-btn {
  background-color: #c80000 !important;
  font-weight: 700 !important;
  font-family: "Montserrat", "ZahraArabicBold" !important;
  font-size: 1.1rem !important;
}
