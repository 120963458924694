.login-notfound{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(
      90deg,
      rgba(247, 246, 247, 1) 0%,
      rgba(185, 185, 185, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-name-error{
  color: #ed1c24!important;
}
.input-merchant-text{
  outline: none!important;
  box-shadow: none!important;
  border: none!important;
  font-weight: 600!important;
  font-size: 0.9rem!important;
}
.input-merchant-text:focus{
  box-shadow: none!important;
  outline: none!important;
  border: none!important;
}