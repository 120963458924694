.cashier-home * {
  font-family: "Montserrat", "ZahraArabic" !important;
}
.cashier-home {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
  display: flex;
  justify-content: center;
}
.merchant-logo {
  position: relative;
  min-width: 75px !important;
  min-height: 75px !important;
  border-radius: 50%;
  background-color: #ed1c24;
}
.merchant-logo .logo-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.cashier-home-title {
  font-weight: 800;
  font-size: 1.2rem;
}
.cashier-home-category {
  font-weight: 600;
  font-size: 0.8rem;
}

.cashier-redeem-content {
  min-height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ed1c24;
  color: white;
  border: 3px solid white;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
}

.cashier-redeem-content img {
  max-width: 50vw;
  height: auto;
}
.cashier-redeem-content h5 {
  font-size: 1.2rem;
  font-weight: 800;
}
.cashier-merchant-logo {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50%;
  background-color: #ed1c24;
  object-fit: contain;
}

@media (max-width: 420px) {
  .cashier-home-title {
    font-weight: 800;
    font-size: 0.9rem;
  }
  .cashier-home-category {
    font-weight: 600;
    font-size: 0.8rem;
  }
}
