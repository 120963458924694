.login * {
  font-family: "Montserrat" !important;
}

.login {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-title {
  font-size: 1.5rem;
  font-weight: 800;
  color: #6d6d6d;
}

.login-page-subtitle {
  font-size: 1rem;
  font-weight: 600;
  color: #ed1c24;
}
.login-page-subtitle span {
  font-weight: 800;
}

.login-photo {
  width: 80%;
  height: auto;
  object-fit: contain;
}

.login-form-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}

.login-input .login-input-icon {
  background-color: #df4d53 !important;
  border: none !important;
  color: #8e0f14 !important;
  border-radius: 15px 0px 0px 15px !important;
}
.login-input .login-input-field {
  background-color: #df4d53 !important;
  border: none !important;
  color: whitesmoke !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  height: 40px !important;
  border-radius: 0px 15px 15px 0px !important;
  padding-left: 25px !important;
}

.login-input .login-input-field:focus {
  box-shadow: none !important;
  color: #f4f3f4 !important;
  font-size: 0.8rem;
  font-weight: 700;
}

.login-input .login-input-field::placeholder {
  color: gainsboro;
  font-weight: 600;
  font-size: 0.8rem;
}

.remember-check {
  width: 1.2em;
  height: 1.2em;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 700;
  background-color: #d8d8d8;
  border: 2px solid #ed1c24;
}
.remember-check:checked {
  background-color: #ed1c24;
  box-shadow: none;
  border: none;
}

.remember-check:focus {
  box-shadow: none !important;
  border: 2px solid #ed1c24;
}

.remember-check-label {
  color: white;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  font-family: "Montserrat" !important;
  line-height: 20px !important;
}

.forget-link {
  text-decoration: none;
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 700;
}

.forget-link:hover {
  color: #f5f5f5;
}

.login-btn {
  background-color: #ed1c24 !important;
  color: #f5f5f5 !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}


.poweredby-photo {
  width: 100px;
  height: auto;
  object-fit: contain;
}

.account-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #DF4D53!important;
  color: white;
  font-weight: 500!important;
  font-size: 0.75rem!important;
}

.account-input:checked + label {
  border: none !important;
  outline: none !important;
  background-color: #8e0f14 !important;
  color: gainsboro !important;
  transition: all 0.1s;
}
.account-input:hover + label{
  color: white!important;
}


@media (max-width: 767px) {
  .login-photo {
    display: none !important;
  }
}
