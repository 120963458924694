[dir="ltr"] .capture-receipt * {
  font-family: "Montserrat" !important;
  font-size: 0.75rem;
}
.capture-icon-content{
  min-width: 32px;
  min-height: 32px;
  background-color: rgb(255, 255, 255,0.95);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.capture-icon{
  font-size: 0.9rem!important;
  color: black;
}
video{
  width: 100%!important;
}

.emptyField{
  width: 100%;
  height: 300px!important;
  background-color: gainsboro;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyField > svg{
  font-size: 8rem!important;
  color: #d3d3d3;
}
.receipt-photo{
  width: 100%;
  height: 300px!important;
  background-color: gainsboro;
  border-radius: 10px;
  object-fit: contain;
}