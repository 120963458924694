.congrats * {
  font-family: "adoody" !important;
}
.congrats {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.gift-image{
    max-height: 160px!important;
}
.congrats-title{
    font-size: 4rem!important;
    font-weight: 600!important;
}
.congrats-subtitle{
    font-size: 1.6rem!important;
}
.congrats-thanks{
    font-family: "Hamdy"!important;
    color: #c80000!important;
    font-size: 1rem!important;
}
.voucher-link{
    font-size: 1.2rem!important;
    color: #626262;
}
.click-here{
    background-color: transparent!important;
    color: #626262;
}
.click-here:hover{
    color: #4c4b4b;
}