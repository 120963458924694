.change-language{
    text-align: right;
}
.lang-btn{
    color: #CA171E;
    font-size: 12px!important;
    font-weight: 700!important;
    font-family: "Montserrat"!important;
}
[dir="rtl"] .lang-btn{
    font-size: 16px!important;
    font-weight: 700!important;
    font-family: "ZahraArabic"!important;
}
.lang-btn:hover{
    background-color: gainsboro!important;
    color: #CA171E!important;
}