.modal-body-otp,
.modal-content {
  background-color: #e4e4e4 !important;
  border-radius: 15px;
}
.otp-group {
  background-color: white;
  border-radius: 15px;
}
.otp-group-list {
  background-color: #f1f0f0;
  border-radius: 15px;
}
.otp-input {
  border: 2px solid #c80000 !important;
  border-radius: 10px !important;
  outline: none !important;
  font-weight: 800 !important;
  font-family: "Montserrat" !important;
  color: #626262!important;
}
.otp-input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.third-step-label {
  font-family: "adoody" !important;
  font-size: 1.3rem !important;
  color: #626262 !important;
}
.note-otp{
  font-family: "Montserrat"!important;
}
[dir = "rtl"] .note-otp{
  font-family: "adoody"!important;
  font-size: 1.3rem!important;
}
