.redeem * {
  font-family: "Montserrat", "ZahraArabic" !important;
}
.redeem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
}
.redeem-title {
  font-weight: 800;
}
.redeem-step {
  position: relative;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  min-height: 70vh;
}

.redeem-step h6 {
  text-align: center;
  font-weight: 800;
}
.scan-content,
.manual-content {
  cursor: pointer;
}

.redeem-step .scan-content,
.redeem-step .manual-content,
.redeem-step .otp-content {
  background-color: #e4e4e4;
  border-radius: 10px;
}

.redeem-input {
  background-color: white !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
}
.redeem-input:focus {
  outline: none !important;
  border: none;
  border-left: 5px solid #cd1118 !important;
  box-shadow: none !important;
  transition: all 0.3s;
}

.redeem-input:disabled {
  background-color: #f2f2f2 !important;
}

/* First Step - START */
.scan-content .step-label,
.manual-content .step-label {
  display: block;
  text-align: center;
  font-size: 1.1rem;
  color: #5d5d5d;
  font-weight: 700;
  cursor: pointer;
}

.scan-icon {
  font-size: 1.3rem !important;
}

.send-otp-btn {
  background-color: #ec1c24 !important;
  font-size: 0.9rem !important;
  font-weight: 800 !important;
  border: none !important;
  outline: none !important;
  border-radius: 10px !important;
}

.send-otp-btn:hover {
  border: none;
  outline: none;
  background-color: #cd1118 !important;
}

/* First Step - END */
/* Second Step - START */
.alert-redeem {
  font-size: 0.8rem;
}

.otp-content .step-label {
  display: block;
  text-align: center;
  font-size: 1.1rem;
  color: #5d5d5d;
  font-weight: 700;
}

.redeem-btn {
  background-color: #ec1c24 !important;
  font-size: 0.9rem !important;
  font-weight: 800 !important;
  border: none !important;
  outline: none !important;
  border-radius: 10px !important;
}

.redeem-btn:hover {
  border: none;
  outline: none;
  background-color: #cd1118 !important;
}
.gift-content {
  font-size: 0.8rem;
  color: black;
  font-weight: 600;
}
.gift-content .otp-icon-content {
  background-color: #e4e4e4 !important;
  font-size: 0.9rem;
  color: #5d5d5d !important;
  font-weight: 800 !important;
}
.ft-large {
  font-size: 0.9rem;
}
/* Second Step - END */
.text-redeem-error {
  color: #cd1118 !important;
  font-size: 0.6rem !important;
  font-weight: 600 !important;
}

.overlay-not-active {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgb(93, 93, 93, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 15px;
  cursor: not-allowed;
}
.font-time{
  font-weight: 700!important;
  font-size: 1rem!important;
}