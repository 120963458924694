[dir="rtl"] .login-notfound * {
  font-family: "ZahraArabic", "Montserrat" !important;
}
[dir = "rtl"] .get-login-btn{
    font-size: 1.2rem!important;
    font-weight: 700!important;
    background-color: #ec1c24;
    color: white;
}
[dir = "rtl"] .input-merchant-text{
    font-size: 1rem!important;
}
[dir = "rtl"] .input-merchant-text:focus{
    font-family: "Montserrat"!important;
    font-size: 0.8rem!important;
    font-weight: 800;
}
