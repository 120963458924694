[dir="rtl"] .login * {
  font-family: "ZahraArabicBold" !important;
}

[dir="rtl"] .remember-check-label {
  font-size: 1.2rem !important;
}

[dir="rtl"] .login-input .login-input-field {
  background-color: #df4d53 !important;
  border: none !important;
  color: #8e0f14 !important;
  height: 40px !important;
  border-radius: 15px 0px 0px 15px !important;
  padding-left: 25px !important;
}
[dir="rtl"] .login-page-title {
  font-size: 2.5rem !important;
}
[dir="rtl"] .login-page-subtitle {
  font-size: 1.6rem !important;
}
[dir="rtl"] .login-input .login-input-icon {
  background-color: #df4d53 !important;
  border: none !important;
  color: #8e0f14 !important;
  border-radius: 0px 15px 15px 0px !important;
}
[dir="rtl"] .login-form-title {
  color: #ed5a60 !important;
  font-size: 1.5rem !important;
}
[dir="rtl"] .login-input .login-input-field::placeholder,
[dir="rtl"] .login-btn {
  font-size: 1.1rem !important;
}
[dir="rtl"] .login-input .login-input-field:focus {
  font-size: 1.2rem !important;
  color: whitesmoke !important;
}
[dir="rtl"] .remember-check-label,
[dir="rtl"] .forget-link {
  color: #ed5a60 !important;
  font-size: 1.3rem !important;
  line-height: 20px !important;
}
[dir="rtl"] .login-input .login-input-field {
  font-weight: 600 !important;
  font-size: 1.3rem !important;
  color: whitesmoke!important;
}

[dir = "rtl"] .account-btn{
  font-size: 1rem!important;
}