.create-cashier * {
  font-family: "Montserrat" !important;
}
.create-cashier {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
}

.add-cashier-content {
  background-color: #e4e4e4 !important;
  border-radius: 15px;
}
.add-cashier-content .add-cashier-title {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
}

.add-cashier-content .add-cashier-form {
  background-color: white;
  border-radius: 15px !important;
}
.add-cashier-form label {
  font-size: 0.8rem !important;
  font-weight: 700 !important;
  color: #626262 !important;
}
.store-select-group {
  background-color: #e4e4e4;
  border-radius: 10px;
}
.add-cashier-text-error {
  color: #ec1c24 !important;
  font-weight: 500 !important;
  font-size: 0.7rem !important;
}
.add-cashier-input-field{
  background-color: #e4e4e4;
  font-size: 0.9rem!important;
  font-weight: 600!important;
  color: #626262!important;
}
.add-cashier-input-field:focus{
  border: 2px solid #ec1c24;
  box-shadow: none;
  transition: all 0.2s;
}
.add-new-cashier-btn{
  font-size: 0.9rem!important;
  font-weight: 700!important;
}