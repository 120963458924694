@font-face {
  font-family: 'adoody';
  src: url(../src/fonts/adoody.ttf);
}
@font-face {
  font-family: "Hamdy";
  src: url(../src/fonts/Hamdy-V2-Bold.ttf);
}
@font-face {
  font-family: "PlexarBold";
  src: url(../src/fonts/ar/IBMPlexSansArabic-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: "PlexarExtraLight";
  src: url(../src/fonts/ar/IBMPlexSansArabic-ExtraLight.ttf);
}
@font-face {
  font-family: "PlexarLight";
  src: url(../src/fonts/ar/IBMPlexSansArabic-Light.ttf);
}
@font-face {
  font-family: "PlexarMedium";
  src: url(../src/fonts/ar/IBMPlexSansArabic-Medium.ttf);
}
@font-face {
  font-family: "PlexarRegular";
  src: url(../src/fonts/ar/IBMPlexSansArabic-Regular.ttf);
}
@font-face {
  font-family: "PlexarSemiBold";
  src: url(../src/fonts/ar/IBMPlexSansArabic-SemiBold.ttf);
}
@font-face {
  font-family: "PlexarThin";
  src: url(../src/fonts/ar/IBMPlexSansArabic-Thin.ttf);
}
@font-face {
  font-family: "ZahraArabic";
  src: url(../src/fonts/ar/18618-zahra.arabic.otf);
}
@font-face {
  font-family: "ZahraArabicBold";
  src: url(../src/fonts/ar/ZahraArabic-Bold.otf);
}
@font-face {
  font-family: "BeinBlack";
  src: url(../src/fonts/ar/beINBlack.ttf);
}





