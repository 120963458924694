[dir = "rtl"] .redeem-input{
    font-size: 1rem!important;
}

[dir = "rtl"] .scan-content .step-label,
[dir = "rtl"] .manual-content .step-label,
[dir = "rtl"] .otp-content .step-label{
    font-size: 1.1rem!important;
}
[dir = "rtl"] .send-otp-btn,
[dir = "rtl"] .redeem-btn{
    font-size: 1.2rem!important;
}
[dir = "rtl"] .alert-redeem{
    font-size: 1.1rem!important;
}
[dir = "rtl"] .text-redeem-error{
    font-size: 0.9rem!important;
}