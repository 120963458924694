.cashier-report {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(247, 246, 247, 1) 0%,
    rgba(185, 185, 185, 1) 100%
  );
}

.report-title {
  font-weight: 800;
}
.reports-table {
  width: 100%;
  height: 325px;
  border-radius: 15px !important;
}
.ag-header-cell-label {
  justify-content: center;
}
.ag-row .ag-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
