.customer-second-step {
  min-height: 100vh;
  min-width: 100%;
  overflow: auto;
  background-image: url("../../../../imgs/bgstep.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.welcome-dandy-logo {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
@media (max-width: 767px) {
  .welcome-dandy-logo {
    height: 200px;
  }
}
.second-step-label {
  font-family: "adoody" !important;
  font-size: 1.3rem !important;
  color: #626262 !important;
}
.second-step-text {
  font-family: "adoody" !important;
  font-size: 1.3rem !important;
  color: #c80000 !important;
}
.items-parent {
  border-radius: 10px;
  background-color: gainsboro;
}
.items-parent .second-step-items {
  width: 100%;
  min-height: 40vh;
  background-color: white;
}
.pick-items {
  border-radius: 10px;
  max-height: 280px !important;
  width: 100%;
  background-color: gainsboro;
  overflow-y: scroll;
}
.item-element {
  min-height: 230px;
  border-radius: 15px;
  border: 1px solid gray !important;
  background-color: transparent;
  cursor: pointer !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active {
  background-color: white;
}

.item-element .item-pic {
  width: 100%;
  height: 100px;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
  background-color: whitesmoke;
}
.item-element .item-info h5 {
  font-family: "Montserrat" !important;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 3px;
}
.item-element .item-info h6 {
  font-family: "Montserrat" !important;
  font-size: 0.7rem;
  font-weight: 500;
}
.item-element .item-price {
  min-width: 100%;
  background-color: #c80000;
  text-align: center;
  border-radius: 10px;
}
.item-element .item-price span {
  color: white;
  font-weight: 800;
  font-family: "Montserrat" !important;
  font-size: 0.85rem;
}
.gift-icon {
  font-size: 1rem;
  color: #ffffff;
}

.vouch-input:checked + label {
  background-color: white !important;
  border: none !important;
  transition: all 0.1s;
}
.text-error {
  color: #ec1c24 !important;
  font-weight: 500 !important;
  font-size: 0.7rem !important;
}
.ft-small {
  font-size: 0.5rem !important;
}
.item-element-content {
  min-width: 150px !important;
  min-height: 230px!important;
}

.second-step-label,
.second-step-text {
  font-family: "Montserrat" !important;
  font-size: 0.8rem!important;
  font-weight: 600!important;
}
