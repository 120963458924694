[dir="rtl"] .customer-home * {
  font-family: "adoody" !important;
}

[dir="rtl"] .start-btn {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(216, 113, 120, 1) 0%,
    rgba(189, 37, 54, 1) 100%
  );
  color: white;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  font-family: "adoody" !important;
}
