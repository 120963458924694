[dir="rtl"] .customer-third-step * {
  font-family: "adoody" !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

[dir="rtl"] .gender-radio-btn {
  font-weight: 600 !important;
}

[dir="rtl"] .text-error {
  color: #ec1c24 !important;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

[dir="rtl"] .third-step-input:focus {
  font-family: "Montserrat", "adoody" !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
[dir="rtl"] .third-step-input{
  font-family: "Montserrat", "adoody" !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
}

[dir="rtl"] .third-step-mobile-input:focus {
  font-family: "Montserrat", "adoody" !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
}
