.customer-third-step * {
  font-family: "Montserrat" !important;
}
.customer-third-step {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../../imgs/bgstep.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.welcome-dandy-logo {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.third-step-content .third-step-form {
  background-color: #ececec;
  border-radius: 10px;
}
.third-step-form .third-step-group {
  border-radius: 15px;
  background-color: white !important;
}
.third-step-form label,
.third-step-form .third-step-text {
  font-size: 0.8rem !important;
  color: #626262 !important;
  font-weight: 600 !important;
}
.third-step-form .third-step-text span {
  font-size: 0.7rem;
  color: #626262 !important;
  font-weight: 700 !important;
}
.third-step-form .input-third-step {
  background-color: #e4e4e4;
  border: none;
  outline: none;
  color: #626262 !important;
  font-weight: 800;
}

.third-step-price {
  text-align: center !important;
  font-family: "Montserrat" !important;
}
.third-step-form .input-third-step:focus {
  border: 2px solid #c80000;
  box-shadow: none !important;
  transition: all 0.1s;
  color: #626262 !important;
}
.third-step-input {
  background-color: #e4e4e4 !important;
  border: none;
  outline: none;
  color: whitesmoke !important;
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  text-align: center;
  border-radius: 20px !important;
}
.third-step-input::placeholder {
  color: white !important;
}
.third-step-input:focus {
  border: 2px solid #c80000 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 0.8rem !important;
  transition: all 0.1s;
  color: #626262 !important;
}
.third-start-btn {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(216, 113, 120, 1) 0%,
    rgba(189, 37, 54, 1) 100%
  );
  color: white;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
  font-family: "Montserrat" !important;
}

.gender-radio-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35px !important;
  background-color: white !important;
  color: #626262 !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
  font-size: 10px !important;
  border: 2px solid #626262 !important;
  outline: none !important;
}

.male-icon,
.female-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}

@media (max-width: 767px) {
  .welcome-dandy-logo {
    height: 200px;
  }
}

.gender-input:checked + label {
  border: none !important;
  outline: none !important;
  background-color: #e4e4e4 !important;
  color: white !important;
  transition: all 0.2s;
}
