[dir = "lrt"] .customer-first-step * {
  font-family: "Montserrat"!important;
}
.customer-first-step {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../../imgs/bgstep.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.first-step-content {
  width: 100%;
}
.welcome-dandy-logo {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.first-step-content .step-form {
  background-color: #ececec;
  border-radius: 10px;
}
.first-step-form {
  background-color: #ececec !important;
}
.first-step-form .first-step-group {
  border-radius: 15px;
  background-color: white !important;
}
.first-step-form label,
.first-step-form .first-step-text {
  font-size: 0.9rem !important;
  color: #626262 !important;
  font-weight: 600 !important;
}
.first-step-form .first-step-text span {
  font-size: 0.7rem;
  color: #626262 !important;
  font-weight: 700 !important;
}
.first-step-form .input-first-step {
  background-color: #e4e4e4;
  border: none;
  outline: none;
  color: #626262 !important;
  font-weight: 800;
}
.input-first-step-font,
.input-first-step-font option,
.receipt-note {
  font-family: "Montserrat" !important;
  font-size: 0.8rem !important;
}
.first-step-price {
  text-align: center !important;
}
.first-step-form .input-first-step:focus {
  border: 2px solid #c80000;
  box-shadow: none !important;
  transition: all 0.1s;
  color: #626262 !important;
}
.receipt-pic {
  width: 100% !important;
  height: 100%;
  max-height: 160px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ede9e9;
  border-radius: 5px;
}
.receipt-pic .image-icon {
  font-size: 100px !important;
  color: rgb(128, 128, 128, 0.2);
}
.capture-pic span {
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.first-start-btn {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(216, 113, 120, 1) 0%,
    rgba(189, 37, 54, 1) 100%
  );
  color: white;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
  font-family: "Montserrat" !important;
}

@media (max-width: 767px) {
  .welcome-dandy-logo {
    height: 200px;
  }
}
.upload-btn,
.crop-btn {
  font-weight: 700 !important;
}
.modal-crop-title {
  font-weight: 800 !important;
  font-size: 1.2rem !important;
}

.close-icon {
  font-size: 1.5rem !important;
  color: #626262 !important;
}
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #c80000 !important;
}
input[type="range"]::-webkit-slider-thumb {
  background: white !important;
}
.degree-num {
  font-weight: 700 !important;
  font-size: 1rem !important;
}
