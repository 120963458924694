.customer-home {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url("../../../imgs/Customerland.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dandy-logo,
.card-logo {
  width: 100%;
  height: 180px;
  object-fit: contain;
}
.start-btn {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(216, 113, 120, 1) 0%,
    rgba(189, 37, 54, 1) 100%
  );
  color: white;
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  font-family: "Montserrat" !important;
}
